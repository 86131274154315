import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/layout/layout";

import ProfileCard from "../../components/sections/about/ProfileCard";

import SEO from "../../components/seo";

const About = () => {
  const data = useStaticQuery(graphql`
    {
      SWAContent {
        members {
          name
          linkedinLink
          imgURL
          designation
          details
          description
          achievements {
            name
          }
          awards {
            name
          }
          contributions {
            name
          }
          experiences {
            name
          }
        }
      }
    }
  `);

  const [members, setMembers] = useState(data.SWAContent.members);
  // if (members) {
  //   console.log("about page: ", members);
  // }

  return (
    <>
      {/* --- background pattern --- */}
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Singapore Women's Association Board Members"
          description="Profile information of SWA board members"
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <h1 className="text-swa-3">Executive Committee Members</h1>

          <div className="flex flex-col items-center mt-10 space-y-9">
            {members.map((member, i) => (
              <ProfileCard key={i} member={member} />
            ))}
          </div>
        </section>
      </Layout>
    </>
  );
};

export default About;
