import React from "react";

import ReactHtmlParser from "react-html-parser";

const ProfileInfo = ({ showMod, member, handleClose }) => {
  // console.log("modal member info", member);
  // console.log("modal itemId info", itemId);
  // console.log("modal showMod ", showMod);
  return (
    <>
      {/* ------ MODAL ------- */}

      <div
        className={
          showMod
            ? "visible opacity-100  inset-0 z-50 flex items-start justify-center transition-all duration-200 ease-out bg-black bg-opacity-70 fixed overflow-y-auto "
            : "invisible opacity-0  inset-0 z-50 flex items-start justify-center transition-all duration-200 ease-in bg-black bg-opacity-70  fixed overflow-y-auto "
        }
        onClick={handleClose}
        onKeyDown={handleClose}
        onFocus={handleClose}
        role="close menu"
        aria-modal="true"
      >
        <div className="w-11/12 p-5 mt-20 bg-white rounded-lg lg:w-6/12">
          {member.name && (
            <div>
              <h2 className=" text-swa-3">{member.name}</h2>
            </div>
          )}
          {member.designation && (
            <div>
              <p className="mb-5 text-swa-3">{member.designation}</p>
            </div>
          )}
          {member.description && (
            <>
              {/* <h3 className="pt-2 text-lg font-black text-swa-3">
                Description
              </h3> */}
              <div className="mb-5 text-lg">{member.description}</div>
            </>
          )}
          {member.details && (
            <>
              <div className="text-lg richtext ">
                {ReactHtmlParser(member.details)}
              </div>
            </>
          )}
          {member.summary && (
            <>
              <h3 className="pt-2 text-lg font-black text-swa-3">Summary</h3>
              <p className="mb-5 text-base">{member.summary}</p>
            </>
          )}
          {member.experiences && member.experiences[0].name && (
            <h3 className="pt-2 font-bold text-swa-3 ">Experience</h3>
          )}
          {member.experiences &&
            member.experiences[0].name &&
            member.experiences.map((exp, i) => {
              return (
                <ul key={i}>
                  <li className="ml-5 list-disc">{exp.name}</li>
                </ul>
              );
            })}
          {member.achievements && member.achievements[0].name && (
            <h3 className="pt-2 font-bold text-swa-3 ">Achievements</h3>
          )}
          {member.achievements &&
            member.achievements[0].name &&
            member.achievements.map((exp, i) => {
              return (
                <ul key={i}>
                  <li className="ml-5 list-disc">{exp.name}</li>
                </ul>
              );
            })}
          {member.contributions && member.contributions[0].name && (
            <h3 className="pt-2 font-bold text-swa-3 ">Contributions</h3>
          )}
          {member.contributions &&
            member.contributions[0].name &&
            member.contributions.map((exp, i) => {
              return (
                <ul key={i}>
                  <li className="ml-5 list-disc">{exp.name}</li>
                </ul>
              );
            })}
          {member.awards && member.awards[0].name && (
            <h3 className="pt-2 font-bold text-swa-3 ">Awards</h3>
          )}
          {member.awards &&
            member.awards[0].name &&
            member.awards.map((exp, i) => {
              return (
                <ul key={i}>
                  <li className="ml-5 list-disc">{exp.name}</li>
                </ul>
              );
            })}

          <div className="w-full mt-8 btn-dark">close</div>
        </div>
      </div>
    </>
  );
};

export default ProfileInfo;
